<template>
    <contenWrap>
        <div class="materialCenter" >
            <div class="contents" v-loading="loading">
            <div class="left-wrap">
                <div class="type-wrap">
                    <div class="type-item" @click="clickAllImage" :class="selectedForAll ? 'typeSelected' : ''">
                        <div class="type">全部图片</div>
                        <span class="addType-btn" @click="addTypeList">添加分类</span>
                    </div>
                    <div class="" v-for="(item,index) in typeList" :key="index">
                        <div class="type-item" :class="item.selected ? 'selected': ''">
                            <div class="type" >
                                <div class="type-iocn-show" @click="clickShow(index)" >
                                    <i class="icon" :class="curIndex == index? 'el-icon-caret-bottom': 'el-icon-caret-right'" v-show="item.childrenNode.length != 0"></i>
                                </div>
                                <div class="type-name" @click="clickTypeItem(item,index)">
                                    {{item.data.classifyName}}
                                </div>
                            </div>
                            <div class="type-icon">
                                <i class="el-icon-circle-plus-outline" @click="allImage(item.data.id)"></i>
                                <i class="el-icon-edit" @click="editType(item.data.id)"></i>
                                <i class="el-icon-delete" @click="delType(item.data.id)"></i>
                            </div>
                        </div>
                        <!-- 二级 -->
                        <div class="type-item-2" :class="curIndex == index? 'isActive': ''" v-for="(second,index1) in item.childrenNode" :key="index1">
                            <div class="type-item">
                                <div class="type">
                                    <div class="type-iocn-show" @click="clickShowSecond(index1)" >
                                        <i class="icon el-icon-caret-right" v-show="second.childrenNode.length != 0"></i>
                                    </div>
                                    <div @click="clickTypeItem(item,index)">
                                        {{second.data.classifyName}}
                                    </div>
                                </div>
                                <div class="type-icon">
                                    <i class="el-icon-circle-plus-outline" @click="allImage(second.data.id)"></i>
                                    <i class="el-icon-edit" @click="editType(second.data.id)"></i>
                                    <i class="el-icon-delete" @click="delType(second.data.id)"></i>
                                </div>
                            </div>
                            <!-- 三级 -->
                            <div class="type-item-3" :class="curIndexSecond == index1? 'isActiveSecond': ''" v-for="(three,index2) in second.childrenNode" :key="index2">
                                <div class="type-item" @click="clickTypeItem(item,index)">
                                    <div class="type">{{three.data.classifyName}}</div>
                                    <!-- <div class="type-icon">
                                        <i class="el-icon-circle-plus-outline" @click="allImage"></i>
                                        <i class="el-icon-edit" @click="editType"></i>
                                        <i class="el-icon-delete" @click="delType"></i>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right-wrap">
                <div class="upload-btn">  
                    <div class="btn">
                        <el-upload
                            class="upload-demo"
                            action="/"
                            :file-list="goodsImages"
                            :auto-upload="false"
                            multiple
                            :on-change="uploadChangeMinipro"
                            >
                            <el-button size="small" type="primary">上传图片</el-button>
                        </el-upload>
                        <el-upload class="del"
                                action="https://elk.c-radar.cn/shop/media/uploadVideoUrl"
                                :data="{FoldPath:'上传目录',SecretKey:'安全验证'}"
                                :on-success="handleVideoSuccess"
                                :before-upload="beforeUploadVideo"
                                :show-file-list="false">
                            <el-button size="small" type="primary">上传视频</el-button>
                        </el-upload>
                        <!-- <el-upload
                            class="del"
                            action="/"
                            :auto-upload="false"
                            :on-change="handleVideoSuccess"
                            >
                            <el-button size="small" type="primary">上传视频</el-button>
                        </el-upload> -->
                        <!-- <el-button size="small" type="success" class="del" @click="addTypeList">添加分类</el-button> -->
                        <el-button size="small" class="del" @click="delMaterials">删除</el-button>
                    </div>
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全部使用</el-checkbox>
                </div>
                <div class="select-wrap">
                    <!-- <el-select v-model="moveImgValue" size="small" placeholder="图片移动至">
                        <el-option
                        v-for="item in moveImgOptions" 
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>  -->
                    <el-cascader
                        placeholder="图片移动至"
                        :options="typeOptions"
                        :props="{ checkStrictly: true }"
                        @change="imagTypeChange"
                        clearable></el-cascader>

                    <el-button type="" @click="moveImageType">移动</el-button>
                </div>
                <div class="type-wrap">
                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedChange">
                        <div class="img-wrap">
                            <div class="img-item" v-for="(item,index) in imageList"  :key="index">
                            <el-checkbox  :label="item">
                                    <el-image class="image" :src="item.url" v-if="item.type == 'image'"></el-image>
                                    <video  class="video" :src="item.url" v-if="item.type == 'video'"></video>
                                    <div class="title">{{item.fileName}}</div>
                            </el-checkbox>
                            </div>
                        </div>
                    </el-checkbox-group>
                    <div class="page-wrap">
                        <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="currentPage4"
                            :page-sizes="[12, 20, 50, 100]"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="totalRow">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog title="分类" :visible.sync="dialogFormVisible" :close-on-click-modal="false">
            <el-form :model="typeRuleForm" :rules="typeRules" ref="typeRuleForm" class="demo-ruleForm" label-width="100px">
                <el-form-item label="上级分类" prop="parentId">
                     <el-cascader
                        v-model="typeRuleForm.parentId"
                        :options="typeOptions"
                        :props="{ checkStrictly: true }"
                        clearable></el-cascader>
                </el-form-item>
                <el-form-item label="分类名称" prop="classifyName">
                    <el-input v-model="typeRuleForm.classifyName" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetFormType">取 消</el-button>
                <el-button type="primary" @click="submitFormEdit" v-if="isShowBnt">修改</el-button>
                <el-button type="primary" @click="submitFormType" v-else>新建</el-button>
                <!-- <div class="dialog-footer-submit" @click="submitFormEdit" v-if="isShowBnt">修改</div>
                <div class="dialog-footer-submit" @click="submitFormType" v-else>新建</div> -->
            </div>
        </el-dialog>

        </div>
    </contenWrap>
</template>

<script>
import contenWrap from '@/components/contenWrap'
import pageMixin from '@/mixin/pageMixin'
import aliOssClient from '@/methods/aliossUpload'
import UUID from '@/methods/uiid'
export default {
    mixins:[pageMixin],
    components: {
        contenWrap,
    },
    data() {
        return {
            pageMixin_index:'materialCenter',
            typeVlue: '',
            imgValue: '',
            loading: false,
            classifyValue: '',
            imageList:[],
            currentPage4: 1,
            checkAll: false,
            checkedCities: [],
            fileObj: {},
            isIndeterminate: false,
            goodsImages: [],
            //添加分类弹窗
            dialogFormVisible: false,
            typeRuleForm: {
                classifyName: '',
                parentId: '',
            },
            typeOptions: [],
            moveImgValue: '',
            typeList: [],
            curIndex: -1,
            isShow: false,
            curIndexSecond: -1,
            isShowSecond: false,
            chooseImageList: [],
            imageTypeItem: null,
            curId: null,
            isShowBnt: false,
            currentPage: 1,
            pageSize: 12,
            totalRow: 0,
            typeRules: {
                classifyName: [
                    { required: true, message: '请输入分类名称', trigger: 'blur' },
                ],
            },
            selectedForAll:true   //全部点击
        }
    },
    mounted(){
        this.taxonomicStructure();
        this.getStoriesList();
    },
    methods: {
        delMaterials(){
            let id = [];
            this.chooseImageList.forEach(v =>{
                id.push(v.id)
            })
            if(!id){
                this.$message.error("请选择图片")
            }else{
                let params = {
                    ids: id
                }
                this.$API.deleteMediaCatalogName(params).then( res => {
                    if(res.code === 0){
                        this.getStoriesList();
                        this.checkAll = false;
                        this.$message.success("删除素材成功");
                    }
                })
            }
        },
        //移动至哪个类型
        imagTypeChange(val){
            this.imageTypeItem = val[val.length-1]
        },
        //移动图片至
        moveImageType(){
            let ids = [];
            this.chooseImageList.forEach(v =>{
                // id = v.id
                ids.push(v.id)
            })
            if(!ids || !this.imageTypeItem){
                this.$message.error("请选择图片和分类")
            }else{
                let params = {
                    mediaCatalogId: this.imageTypeItem,
                    ids: ids
                }
                this.$API.modifyCategory(params).then( res => {
                    if(res.code === 0){
                        this.$message.success("图片移动成功")
                    }
                })
            }
        },
        // 全部图片
        clickAllImage(){
            this.selectedForAll = true
            this.typeList.forEach(v=>{
                v.selected = false
            })            
            this.getStoriesList();
        },
        // 点击列表显示对应的内容
        clickTypeItem(item,index){
            // console.log(item);
            // console.log(id);
            // console.log(this.typeList);
            this.typeList.forEach((v,i)=>{
                v.selected = false
                if(index === i){
                    v.selected = true
                }
            })
            this.selectedForAll = false     //取消全部选中
            this.getStoriesList(item.data.id)
        },
        //获取分类结构
        taxonomicStructure(){
            this.$API.getListCatalog().then( res => {
                if(res.code === 0){
                    this.typeList = res.data.map(v=>{
                        v.selected = false
                        return v
                    })
                    // this.typeOptions.push(v);
                    let resultSecond;
                    var result = res.data.map(o=>{
                        if(o.childrenNode){
                            resultSecond =  o.childrenNode.map(c=>{
                                return{
                                    value: c.data.id, 
                                    label: c.data.classifyName,
                                }
                            })
                        }
                        return{
                            value: o.data.id, 
                            label: o.data.classifyName,
                            children: resultSecond
                        }
                    });
                    this.typeOptions = result;
                }
            })
        },
        //删除分类
        delType(row){
            this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let ids = [];
                ids.push(row)
                let params = {
                    ids: ids,
                }
                this.$API.deleteMediaCatalog(params).then( res => {
                    if(res.code === 0){
                        this.taxonomicStructure();
                        this.$message.success("删除分类成功！");
                    }
                })
            }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
            });
        },
        // 修改分类
        submitFormEdit(){
            this.$refs.typeRuleForm.validate((boolean)=>{
            if(!boolean){
                this.$message.warning('详情未完善')
            }else{
                let params = {
                    id: this.curId,
                    classifyName: this.typeRuleForm.classifyName
                }
                this.$API.updateMediaCatalogName(params).then( res => {
                    if(res.code === 0){
                        this.taxonomicStructure();
                        this.$message.success("修改分类成功！");
                        this.dialogFormVisible = false;
                        this.$refs.typeRuleForm.resetFields();
                    }
                })
            } 
        })
        },
        //编辑分类
        editType(id){
            let params = {
                id: id
            }
            this.$API.getmediaCatalogInfo(params).then( res => {
                if(res.code === 0){
                    this.typeRuleForm.parentId = res.data.parentId;
                    this.typeRuleForm.classifyName = res.data.classifyName;
                    this.curId = id,
                    this.isShowBnt = true;
                    this.dialogFormVisible = true;
                }
            })
        },
        //添加分类
        submitFormType(){
            this.$refs.typeRuleForm.validate((valid) => {
                if (valid) {
                    let parentId = this.typeRuleForm.parentId
                    let id = parentId[parentId.length-1]
                    let params ={
                        parentId: id,
                        classifyName: this.typeRuleForm.classifyName,
                    }
                    this.$API.addTheClassification(params).then( res => {
                        if(res.code === 0){
                            this.dialogFormVisible = false;
                            this.$refs.typeRuleForm.resetFields();
                            this.taxonomicStructure();
                            this.$message.success("添加分类成功")
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        //添加分类按钮
        addTypeList(){
            this.dialogFormVisible = true;
            this.isShowBnt = false;
            this.typeRuleForm.parentId = '';
            this.typeRuleForm.classifyName = '';
        },
        resetFormType() {
            this.$refs.typeRuleForm.resetFields();
            this.dialogFormVisible = false;
        },
        //全部图片 添加分类按钮
        allImage(){ 
            this.dialogFormVisible = true;
        },
        // 上传成功回调
        handleVideoSuccess(response, file, fileList){
            this.loading = true
            this.fileObj = file
            let ossName = UUID.v1()
            aliOssClient.put(`/video/${ossName}`,file.raw,{
                progress:(p)=>{
                    this.progress(p,index)
                }
            }).then(res=>{
                let params = {
                    fileName: file.name,
                    mediaUrl: res.url
                }
                if(res.res.statusCode === 200){
                    this.$API.uploadVideoMaterial(params).then( res => {
                        if(res.code === 0){
                            this.fileObj ={}
                            this.getStoriesList();
                            this.loading = false
                        }
                    })
                }
            })
            .catch((err)=>{
                console.log(err);
                this.$message.warning('上传图片失败')
                return
            })
        },
        //上传视频前回调
        beforeUploadVideo(file) {
            var fileSize = file.size / 1024 / 1024 < 50;
            if (['video/mp4', 'video/ogg', 'video/flv', 'video/avi', 'video/wmv', 'video/rmvb', 'video/mov'].indexOf(file.type) == -1) {
                this.$message.warning("请上传正确的视频格式")
                return false;
            }
            if (!fileSize) {
                this.$message.warning("视频大小不能超过50MB")
                return false;
            }
        },
        // 上传图片
        uploadChangeMinipro(file,fileList){
            this.loading = true
            this.goodsImages = fileList;
            let ossName = UUID.v1()
            aliOssClient.put(`/images/${ossName}`,file.raw,{
                progress:(p)=>{
                    this.progress(p,index)
                }
            }).then(res=>{
                let params = {
                    fileName: file.name,
                    mediaUrl: res.url
                }
                if(res.res.statusCode === 200){
                    this.$API.uploadImageMaterial(params).then( res => {
                        if(res.code === 0){
                            this.getStoriesList();
                            this.goodsImages = []
                            this.loading = false
                        }
                    })
                }
            })
            .catch((err)=>{
                console.log(err);
                this.$message.warning('上传图片失败')
                return
            })
        },
        // 获取素材列表
        getStoriesList(id){
            let params = {
                mediaType: '',
                catalogId: id,
                currentPage: this.currentPage,
                pageSize: this.pageSize
            }
            this.$API.getStoriesList(params).then( res => {
                if(res.code === 0){
                    res.data.list.forEach(v => {
                        v.fileName = v.fileName.substring(0,v.fileName.indexOf("."));
                    })
                    this.imageList = res.data.list;
                    this.totalRow = res.data.pageInfo.totalRow
                }
            })
        },
        //全选
        handleCheckAllChange(val) {
            this.checkedCities = val ? this.imageList : [];
            this.isIndeterminate = false;
            if(val){
                this.chooseImageList = this.imageList
            }else{
                this.chooseImageList = []
            }
        },
        //点击选择
        handleCheckedChange(value) {
            this.chooseImageList = value;
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.imageList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.imageList.length;
        },
        // 每页多少条
        handleSizeChange(val) {
            this.pageSize  = val;
            this.getStoriesList();
        },
        // 当前页
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getStoriesList();
        },
        //添加分类
        addType(){},
        // 删除分类
        // delType(){
        //     this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        //         confirmButtonText: '确定',
        //         cancelButtonText: '取消',
        //         type: 'warning'}).then(() => {
        //         this.$message({
        //             type: 'success',
        //             message: '删除成功!'
        //         });
        //         }).catch(() => {
        //         this.$message({
        //             type: 'info',
        //             message: '已取消删除'
        //         });          
        //     });
        // },
        //一级显示隐藏
        clickShow(index){
            console.log('一级');
            this.isShow = !this.isShow;
            this.selectedForAll = false
            if(this.isShow){
                this.curIndex = index;
            }else{
                this.curIndex = -1
            }
        },
        // 二级显示隐藏
        clickShowSecond(index){
            console.log('二级');
            this.isShowSecond = !this.isShowSecond;
            if(this.isShowSecond){
                this.curIndexSecond = index;
            }else{
                this.curIndexSecond = -1
            }
        },
        submitForm(){},
        resetForm(){},
        getDate(){
            this.isShowAddStoreDialog = true
        },
    }
}
</script>

<style lang="scss">
.materialCenter{
    
    .contents{
        margin-top: 20px;
        padding: 10px 10px;
        background-color: #fff;
        border-radius: 5px;
        border: 1px solid $card-border-color;      
        display: flex;
        flex-wrap: wrap;
        margin: 0 20px;
        .left-wrap{
            // width: 30%;
            min-width: 300px;
            height: 300px;
            flex-shrink: 1;
            // border: 1px solid red;
            .type-wrap{
                margin-top: 10px;
                .typeSelected{
                    background-color: #edf6ff;
                }
                .type-item{
                    padding: 0px 10px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    color: #4386c6;
                    height: 26px;
                    .type{
                        font-size: 14px;
                        cursor: pointer;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .type-iocn-show{
                            width: 20px;
                            .icon{
                                color: #999;
                            }
                        }
                        .type-name{
                            width: 200px;
                        }
                    }                  
                    .addType-btn{
                        font-size: 12px;
                        cursor: pointer;
                        &:hover{
                            font-weight: 600;
                        }
                    }
                    .type-icon{
                        // display: none;
                       i{
                           font-size: 16px;
                           font-weight: 600;
                           padding-left: 10px;
                           cursor: pointer;
                       } 
                    }
                }
                .selected{
                    background-color: #edf6ff;
                }  
                // .type-item:hover{
                //     background: #edf6ff;
                //     .type-icon{display: block;} 
                // }
                .type-item-2{
                    padding-left: 20px;
                    display: none;
                }
                .isActive{
                    display: block;
                }
                .type-item-3{
                    padding-left: 20px;
                    display: none;
                }
                .isActiveSecond{
                    display: block;
                    margin-left: 10px;
                }
            }
        }
        .right-wrap{
            width: 60%;
            margin-top: 20px;
            margin-left: 30px;
            .upload-btn{
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .btn{
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    .del{
                        margin-left: 20px;
                    }
                    .el-upload-list{
                        display: none;
                    }
                }
            }
            .select-wrap{
                .el-input__inner{
                    font-size: 14px;
                }
            }
            .type-wrap{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                .img-wrap{
                    width: 100%;
                    overflow: hidden;
                    display: flex;
                    flex-flow: row wrap;
                    box-sizing: border-box;
                    margin-top: 20px;
                    .img-item{
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 10px;
                        margin-right: 35px;
                        .image{
                            width: 120px;
                            height: 120px;
                        }
                        .video{
                            width: 120px;
                            height: 120px;
                            border: 1px solid #eee;
                        }
                        .title{
                            padding-top: 5px;
                            font-size: 14px;
                            width: 120px;
                            overflow: hidden;
                        }
                        .el-checkbox__input{
                            position: absolute;
                            right: 0px;
                        }
                        .el-checkbox__label{
                            padding-left: 0;
                        }
                    }
                }
                .page-wrap{
                    padding: 20px 0;
                    width: 100%;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                }
            }
        }
    }
    .demo-ruleForm{
        padding-right: 30px;
    }
}

</style>